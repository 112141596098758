
import { defineComponent } from "vue";

export default defineComponent({
    props:{
        extras: {
            type: Array as () => FBorderI.OrderExtraI[],
            required: true,
        },
    },
    methods: {
        modifiers(modifiers: FBorderI.OrderExtraModifierI[]) {
            if (modifiers) {
                return modifiers.map(modifier=> modifier.description).join(' ')
            }
            return ''
        },
    }
});
