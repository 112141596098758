<template>
    <div class="order-overview">
        <div class="order-overview__customer">
            <Customer
                :customerName="customerName"
                :orderNumber="order.orderDetails.orderNumber"
                :method="order.orderDetails.orderMethod"
                :status="order.orderDetails.status"
                :pickupTime="order._pickupDisplay"
                :startTime="order._startTimeDisplay"
                @do-print-all-labels="handlePrintAllLabels"
                @do-handoff="handleHandoff"
            />
        </div>
        <div class="order-overview__items">
            <OrderPies v-if="order.pies" :pies="order.pies" />
            <OrderExtras v-if="order.extras" :extras="order.extras" />
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import Customer from "./components/Customer.vue";
import OrderPies from "./components/OrderPies.vue";
import OrderExtras from "./components/OrderExtras.vue";
import { kdsApi, webApi } from '@/services/api';


export default defineComponent({
    components: { Customer, OrderPies, OrderExtras },
    props: {
        order: {
            type: Object as () => FBorderI.OrderI,
            required: true,
        },
    },
    computed: {
        customerName(): string {
            return `${this.order?.orderDetails?.customerFirstName} ${this.order?.orderDetails?.customerLastName}`;
        }
    },
    methods: {
        handlePrintAllLabels() {
            kdsApi.printLabels(this.order.orderDetails.orderID);
        },
        handleHandoff() {
            webApi.acknowledgeHandoff(this.order.orderDetails.orderID);
        },
    }
})
</script>

<style>
.order-overview {
    padding-top: 22px;
    padding-left: 24px;
    display: flex;
}

.order-overview__items {
    margin-left: 24px;
}

</style>
