<template>
    <div class="order-extras">
        <div class="order-extras__heading">
            <p>EXTRAS</p>
            <p>{{ extras.length }}</p>
        </div>
        <ul class="order-extras__items">
            <li class="order-extras__item" v-for="item of extras" :key="item.name">
                {{ item.name }} {{ modifiers(item.modifiers) }}
            </li>
        </ul>
    </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
    props:{
        extras: {
            type: Array as () => FBorderI.OrderExtraI[],
            required: true,
        },
    },
    methods: {
        modifiers(modifiers: FBorderI.OrderExtraModifierI[]) {
            if (modifiers) {
                return modifiers.map(modifier=> modifier.description).join(' ')
            }
            return ''
        },
    }
});
</script>

<style>
.order-extras {
    width: 310px;
    background-color: var(--color-white);
    border-radius: 2px;
    margin-top: 15px;
}
.order-extras__heading {
    background-color: var(--color-gorse);
    border-bottom: 0.25px solid var(--color-dove-gray);
    padding-left: 15px;
    padding-right: 25px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 56px;
    font-size: 18px;
    line-height: 22px;
    font-weight: var(--weight-bolder);
}
.order-extras__items {
    background-color: var(--color-green-80);
    padding: 20px 25px 11px 15px;
    margin-top: 0;
}
.order-extras__item {
    list-style-type: none;
    font-weight: var(--weight-bold);
    font-size: 14px;
    line-height: 17px;
    text-transform: capitalize;
}
</style>
