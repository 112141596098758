
import { defineComponent } from 'vue';
import Customer from "./components/Customer.vue";
import OrderPies from "./components/OrderPies.vue";
import OrderExtras from "./components/OrderExtras.vue";
import { kdsApi, webApi } from '@/services/api';


export default defineComponent({
    components: { Customer, OrderPies, OrderExtras },
    props: {
        order: {
            type: Object as () => FBorderI.OrderI,
            required: true,
        },
    },
    computed: {
        customerName(): string {
            return `${this.order?.orderDetails?.customerFirstName} ${this.order?.orderDetails?.customerLastName}`;
        }
    },
    methods: {
        handlePrintAllLabels() {
            kdsApi.printLabels(this.order.orderDetails.orderID);
        },
        handleHandoff() {
            webApi.acknowledgeHandoff(this.order.orderDetails.orderID);
        },
    }
})
