<template>
    <div class="order-pies">
        <div class="order-pies__heading">
            <p>PIES</p>
            <p>{{ pies.length }}</p>
        </div>
        <div class="order-pies__pies-status">
            <p class="order-pies__pies-status-count">{{ preBakeCount }}</p>
            <p class="order-pies__pies-status-label">Prepping</p>
        </div>
        <div class="order-pies__pies-status">
            <p class="order-pies__pies-status-count">{{ postBakeCount }}</p>
            <p class="order-pies__pies-status-label">Ready</p>
        </div>
        <div class="order-pies__pies">
            <div class="order-pies__pie" v-for="pie of pies" :key="pie.pieOfOrder+pie.order_item_id">
                <button class="order-pies__pie-heading" @click="togglePieIngredients(pie)">
                    <p class="order-pies__pie-name">{{ pie.name }}</p>
                    <img src="@/assets/carrot-black.svg" class="order-pies__pie-carrot" :class="showPieIngredients(pie) && 'order-pies__pie-carrot--open'" alt="Toggle pie Ingredients">
                </button>
                <Ingredients v-if="showPieIngredients(pie)" :ingredients="pie.ingredients" />
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import Ingredients from "@/components/Ingredients/Ingredients.vue";

export default defineComponent({
    components:{ Ingredients },
    data: ()=>({
        activeIngredientListId: undefined as Number|undefined
    }),
    props:{
        pies: {
            type: Array as () => FBorderI.OrderPieI[],
            required: true,
        }
    },
    computed:{
        preBakeCount(): number{
            return this.pies.filter( (pie)=>pie.chitPrinted ).length;
        },
        postBakeCount(): number{
            return this.pies.filter( (pie)=>pie.labelPrinted ).length;
        },
    },
    methods: {
        togglePieIngredients(pie: FBorderI.OrderPieI){
            if (this.activeIngredientListId == this.pieUID(pie)) {
                this.activeIngredientListId = undefined
            } else {
                this.activeIngredientListId = this.pieUID(pie);
            }
        },
        showPieIngredients(pie: FBorderI.OrderPieI): boolean {
            return pie.ingredients && this.activeIngredientListId == this.pieUID(pie);
        },
        pieUID(pie: FBorderI.OrderPieI) {
            return pie.order_item_id+pie.qtyOfItem;
        }
    }
});
</script>

<style scoped>
.order-pies {
    width: 310px;
    background-color: var(--color-white);
    border-radius: 2px;
    padding-bottom: 11px;
}
.order-pies__heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 18px;
    line-height: 22px;
    font-weight: var(--weight-bolder);
    height: 56px;
    padding: 0 23px 0 17px;
    border-bottom: 0.25px solid var(--color-dove-gray);
    margin-bottom: 10px;
}
.order-pies__heading p {
    margin: 0;
}

.order-pies__pies-status {
    height: 40px;
    display: flex;
    align-items: center;
    border-bottom: 0.25px solid var(--color-dove-gray);
    margin-right: 18px;
    margin-left: 13px;
}
.order-pies__pies-status-count {
    width: 22px;
    height: 22px;
    line-height: 22px;
    border-radius: 100%;
    background-color: rgba(0, 0, 0, 0.1);
    margin-right: 9px;
    text-align: center;
    font-size: 12px;
    font-weight: var(--weight-bold);
}

.order-pies__pies-status-label {
    font-size: 12px;
    line-height: 14px;
    text-transform: capitalize;
}

.order-pies__pies {
    margin-top: 17px;
}

.order-pies__pie-heading {
    width: 100%;
    background-color: transparent;
    border: none;
    height: 25px;
    padding-left: 15px;
    padding-right: 25px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    line-height: 17px;
    text-transform: capitalize;
    font-weight: var(--weight-bold);
    outline: none;
}

.order-pies__pie-carrot--open {
    transform: rotate(180deg);
}
</style>
