<template>
    <div class="shop-orders">
        <div class="shop-orders__orders">
            <OrderListCard
                v-for="order of orders"
                :key="order.orderDetails.orderID"
                :order="order"
                :active="isActive(order)"
                @click="handleOrderOpen(order)"
            />
        </div>
        <div class="shop-orders__overview">
            <OrderOverview v-if="openedOrder" :order="openedOrder"/>
        </div>
    </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue';
import OrderListCard from './components/OrderListCard.vue';
import OrderOverview from "@/components/OrderOverview/OrderOverview.vue";

export default defineComponent({
    components: { OrderListCard, OrderOverview },
    data: ()=>({
        openedOrder: undefined as FBorderI.OrderI | undefined,
    }),
    props: {
        orders: {
            type: Array as () => FBorderI.OrderI[],
            required: true,
        }
    },
    watch:{
        orders(newVal) {
            const findOpenOrder = newVal.find((order:FBorderI.OrderI) => order.orderDetails.orderID == this.openedOrder?.orderDetails?.orderID)
            if (!findOpenOrder) {
                this.openedOrder = undefined;
            }
        }
    },
    methods: {
        handleOrderOpen(order: FBorderI.OrderI) {
            this.openedOrder = order;
        },
        isActive(order: FBorderI.OrderI) {
            return order.orderDetails.orderID == this.openedOrder?.orderDetails?.orderID
        }
    },
})
</script>
<style scoped>
.shop-orders {
    display: flex;
    /* height: 568px; */
    height: calc(100% - 65px);
}

.shop-orders__orders {
    width: 250px;
    background-color: var(--color-mercury);
    overflow-y: scroll;
}

.shop-orders__overview {
    overflow-y: scroll;
    width: calc(100% - 250px);
}
</style>
