<template>
    <div class="order-list-card" :class="tileModifierClasses">
        <p class="order-list-card__customer">{{ order.orderDetails.customerFirstName }} {{ order.orderDetails.customerLastName }}</p>
        <div class="order-list-card__details">
            <p class="order-list-card__number">{{ order.orderDetails.orderNumber }}</p>
            <p class="order-list-card__total">{{ piesReady }} / {{ order.orderDetails.totalPies }}</p>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
    props: {
        order: {
            type: Object as () => FBorderI.OrderI,
            required: true,
        },
        active: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        piesReady(): number {
            if (this.order.pies) {
                return this.order.pies.filter( (pie) => pie.labelPrinted ).length
            }
            return 0
        },
        tileModifierClasses(): string {
            if (this.active) {
                return 'order-list-card--active';
            }
            return this.order._warningColor;
        }
    }
})
</script>

<style scoped>
.order-list-card {
    padding: 5px 15px 5px 22px;
    border-bottom: 0.25px solid var(--color-dove-gray);
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 80px;
    color: var(--color-dove-gray);
}
.order-list-card.tile--alert {
    color: var(--color-white);
}
.order-list-card.tile--warning {
    color: var(--color-black);
}

.order-list-card--active {
    background-color: var(--color-black);
    color: var(--color-white);
}

.order-list-card__customer,
.order-list-card__number,
.order-list-card__total {
    font-weight: var(--weight-bolder);
    font-size: 16px;
    line-height: 19px;
    margin: 0;
}

.order-list-card__details {
    display: flex;
    justify-content: space-between;
}

</style>
