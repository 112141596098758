
import { defineComponent } from 'vue';
import HCbutton from "@/components/HCbutton/HCbutton.vue";

export default defineComponent({
    components: { HCbutton },
    props: {
        customerName: String,
        orderNumber: String,
        method: String,
        status: String,
        pickupTime: String,
        startTime: String,
    },
})
