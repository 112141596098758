
import { defineComponent } from "vue";

export default defineComponent({
    props: {
        order: {
            type: Object as () => FBorderI.OrderI,
            required: true,
        },
        active: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        piesReady(): number {
            if (this.order.pies) {
                return this.order.pies.filter( (pie) => pie.labelPrinted ).length
            }
            return 0
        },
        tileModifierClasses(): string {
            if (this.active) {
                return 'order-list-card--active';
            }
            return this.order._warningColor;
        }
    }
})
