
import { defineComponent } from "vue";
import Ingredients from "@/components/Ingredients/Ingredients.vue";

export default defineComponent({
    components:{ Ingredients },
    data: ()=>({
        activeIngredientListId: undefined as Number|undefined
    }),
    props:{
        pies: {
            type: Array as () => FBorderI.OrderPieI[],
            required: true,
        }
    },
    computed:{
        preBakeCount(): number{
            return this.pies.filter( (pie)=>pie.chitPrinted ).length;
        },
        postBakeCount(): number{
            return this.pies.filter( (pie)=>pie.labelPrinted ).length;
        },
    },
    methods: {
        togglePieIngredients(pie: FBorderI.OrderPieI){
            if (this.activeIngredientListId == this.pieUID(pie)) {
                this.activeIngredientListId = undefined
            } else {
                this.activeIngredientListId = this.pieUID(pie);
            }
        },
        showPieIngredients(pie: FBorderI.OrderPieI): boolean {
            return pie.ingredients && this.activeIngredientListId == this.pieUID(pie);
        },
        pieUID(pie: FBorderI.OrderPieI) {
            return pie.order_item_id+pie.qtyOfItem;
        }
    }
});
