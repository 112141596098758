
import { defineComponent } from 'vue';
import OrderListCard from './components/OrderListCard.vue';
import OrderOverview from "@/components/OrderOverview/OrderOverview.vue";

export default defineComponent({
    components: { OrderListCard, OrderOverview },
    data: ()=>({
        openedOrder: undefined as FBorderI.OrderI | undefined,
    }),
    props: {
        orders: {
            type: Array as () => FBorderI.OrderI[],
            required: true,
        }
    },
    watch:{
        orders(newVal) {
            const findOpenOrder = newVal.find((order:FBorderI.OrderI) => order.orderDetails.orderID == this.openedOrder?.orderDetails?.orderID)
            if (!findOpenOrder) {
                this.openedOrder = undefined;
            }
        }
    },
    methods: {
        handleOrderOpen(order: FBorderI.OrderI) {
            this.openedOrder = order;
        },
        isActive(order: FBorderI.OrderI) {
            return order.orderDetails.orderID == this.openedOrder?.orderDetails?.orderID
        }
    },
})
