<template>
    <div class="customer">
        <div class="customer__heading">
            <p class="customer__name">{{ customerName }}</p>
            <p class="customer__number">{{ orderNumber }}</p>
        </div>
        <p class="customer__times" v-if="pickupTime">
            <span>Pickup: {{ pickupTime }}</span>
            <template v-if="startTime">
                <br>
                <span>Start Time: {{ startTime }}</span>
            </template>
        </p>
        <div class="customer__method" v-if="method">
            {{ method }}
        </div>
        <div class="customer__ctas">
            <HCbutton class="customer__handoff-cta" @click="$emit('do-handoff', orderNumber)" v-if="status != 'cancelled'">HANDOFF</HCbutton>
            <HCbutton @click="$emit('do-print-all-labels', orderNumber)">PRINT ALL LABELS</HCbutton>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import HCbutton from "@/components/HCbutton/HCbutton.vue";

export default defineComponent({
    components: { HCbutton },
    props: {
        customerName: String,
        orderNumber: String,
        method: String,
        status: String,
        pickupTime: String,
        startTime: String,
    },
})
</script>

<style>
.customer {
    background-color: var(--color-white);
    padding-top: 14px;
    padding-bottom: 30px;
    width: 310px;
    border-radius: 2px;
}

.customer__heading {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    padding: 0 17px;
    margin-bottom: 5px;
}

.customer__name {
    font-size: 22px;
    line-height: 26px;
    margin: 0;
    font-weight: var(--weight-bolder);
}

.customer__number {
    font-size: 15px;
    line-height: 18px;
    margin: 0;
    font-weight: var(--weight-bold);
}

.customer__method {
    margin-bottom: 32px;
    height: 50px;
    display: flex;
    align-items: center;
    padding-left: 22px;
    background-color: var(--color-scorpion-85);
    font-size: 16px;
    line-height: 19px;
    font-weight: var(--weight-bolder);
    text-transform: uppercase;
    color: var(--color-white);
}

.customer__ctas {
    padding: 0 22px;
}

button.customer__handoff-cta {
    margin-bottom: 14px;
}

.customer__times {
    font-size: 14px;
    padding: 0 17px;
    margin-bottom: 14px;
}

</style>
